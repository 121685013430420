import React from 'react'
import { Top, Footer, Meta, Breadcrumbs, Advice } from '@src/share-component'
import Container from '@material-ui/core/Container';
import { useIntl } from "gatsby-plugin-react-intl"
import Loading from '@src/share-component/loading/loading';
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SFZ from '@src/solutions/pedestrian/sfz-120/sfz.jpg'
import CP120 from '@src/solutions/pedestrian/cp-120/cp-120.png'
import Cloud from '@src/asset/cloud.jpg'
import Cloud2 from '@src/asset/cloud_2.jpg'
import Cloud3 from '@src/asset/cloud_3.jpg'
import Parking2 from '@src/asset/parking2.jpg'
import Parking5 from '@src/asset/parking5.jpg'
import Parking6 from '@src/asset/parking6.jpg'
import Parking3 from '@src/asset/parking3.jpg'
import Parking4 from '@src/asset/parking4.jpg'
import Parking7 from '@src/asset/parking7.jpg'
// import Pedestrid_1 from '@src/asset/p_1.jpg'
import Pedestrian_2 from '@src/asset/p_2.jpg'
import App_1 from '@src/asset/web.jpg'
import App_2 from '@src/asset/payments.jpg'
import App_3 from '@src/asset/office.jpg'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { navigate } from 'gatsby'
import Card from '@material-ui/core/Card';

import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import './solutions.css'
import Grid from '@material-ui/core/Grid';

const list1 = [1, 2, 3]
const list2 = [1, 2, 3, 4, 5, 6]
const app = [App_1, App_2, App_3]
const category = 'parking'
const solutions_md = 5

const solutions_sm = 5
const solutions_xs = 10
const solutions_xl = 3
const solutions_lg = 3

const SolutionsParking = () => {

  const intl = useIntl()
  const language = intl.locale
  const slugs = [
    { url : `/${intl.locale}/`, name : intl.formatMessage({ id : "slug-home"})},
    { url : `/${intl.locale}/solutions/`, name : intl.formatMessage({ id : "slug-solutions"}) },
    { url : `/${intl.locale}/solutions/${category}/`, name : intl.formatMessage({ id : `slug-${category}`}) },
  ]
  const onClick = (url) => navigate(`${intl.locale}${url}`)

  return <React.Fragment>
    <Meta
     title = {intl.formatMessage({ id : `solutions-${category}-title`})}
     description = {intl.formatMessage({ id : `solutions-${category}-description`})}
     slug = {`/solutions/${category}/`}
     />
    <Loading/>
    <div class = 'page'>
      <Top useColorBar />
      {/* <Top noSlogan = {true} useParagraph = { true } sloganParagraph = { intl.formatMessage({ id : "solutions-pedestrian-content-1-1"}) } /> */}
      <Container classes = {{ root : "solutions-container" }}>

        < Breadcrumbs slugs = {slugs}/>
      </Container>
    <div class = 'solutions-container-bg'>
    <Container classes = {{ root : "solutions-container" }}>

    <Typography variant = "h4" component = "h1" classes = {{ root : 'solutions-block-title' }}> {intl.formatMessage({ id : `solutions-${category}-title`})} </Typography>
    <Typography variant = "body1" component = "p" classes = {{ root : "solutions-block-content" }}>{ intl.formatMessage({ id : `solutions-${category}-content`}) }</Typography>
    </Container>
    </div>
    <div class = 'solutions-card-container'>
    <Grid container alignItem = "center" justify = "center" spacing = {5}>
      {app.map((s, i) => <Grid item classes = {{ root : "index-solutions-grid" }} xs = {solutions_xs} sm = {solutions_sm} md = {solutions_md} lg = {solutions_lg} xl = {solutions_xl}>
      <Card elevation = {5} classes = {{ root : "index-solutions-card" }}>
      <CardActionArea>
        <CardMedia
          component = "img"
          alt = {intl.formatMessage({ id : `solutions-${category}-card-${i}-title`})}
          height = "200"
          image = {s}
          title = {intl.formatMessage({ id : `solutions-${category}-card-${i}-title`})}
        />
        <CardContent>
          <Typography align = "left" classes = {{ root : 'index-solutions-title' }} gutterBottom variant = "h6" component = "h3">
            {intl.formatMessage({ id : `solutions-${category}-card-${i}-title`})}
          </Typography>
          <Typography align = "left"  classes = {{root : 'index-solutions-content'}} variant = "body1" color = "textSecondary" component = "h4">
            {intl.formatMessage({ id : `solutions-${category}-card-${i}-content`})}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </Grid>
    )}
  </Grid>
  </div>

    <div class = 'solutions-block'>
      <img src = {Parking6} class = "solutions-block-bg" />
      <div class = 'solutions-block2'>
      <Typography variant = "h4" component = "h2" classes = {{ root : 'solutions-block2-title' }}> {intl.formatMessage({ id : `solutions-${category}-title-1`})} </Typography>
      <Typography variant = "body1" component = "p" classes = {{ root : "solutions-block2-content" }}>
        { intl.formatMessage({ id : `solutions-${category}-content-1-1`}) }
      </Typography>
      <List>
        {
          list1.map((l) => <ListItem>
            <ListItemIcon classes = {{ root : "solutions-list-item" }}></ListItemIcon>
            <Typography variant = "body1" component = "h6" classes = {{ root : 'solutions-list-item-text' }}> {intl.formatMessage({ id : `solutions-${category}-list-1-${l}`})} </Typography>
            {/* <ListItemText component = "h6" classes = {{ root : "solutions-list-item-text" }} primary = "Access control devices, such as access cards and card readers"></ListItemText> */}
          </ListItem>
          )
        }
      </List>
      </div>
    </div>
    {/* <Container className = "solutions-container">
    <Grid alignContent = "space-between" justify = "space-between" spacing = {3} container className = "solutions-card-list">
        {
          list1.map((l) => <Grid item><Card elevation = {5} className = {"solutions-card"}>
          <CardContent>
            <Typography variant="h5" component = "h3" className = {"solutions-card-title"} color = "textSecondary" gutterBottom>
              { intl.formatMessage({ id : `solutions-pedestrian-card-1-${l}-1`}) }
            </Typography>
            <Typography variant="body2" component="p">
              { intl.formatMessage({ id : `solutions-pedestrian-card-1-${l}-2`}) }
            </Typography>
            <Typography className = {""} color="textSecondary">
              { intl.formatMessage({ id : `solutions-pedestrian-card-1-${l}-3`}) }
            </Typography>
            <Typography variant="body2" component="p">
              { intl.formatMessage({ id : `solutions-pedestrian-card-1-${l}-4`}) }
            </Typography>
          </CardContent>
        </Card>
        </Grid>
          )
        }
      </Grid>
      </Container> */}
    <div class = 'solutions-block'>
      <img src = {Parking7} class = "solutions-block-bg" />
      <div class = 'solutions-block2-right'>
      <Typography variant = "h4" component = "h2" classes = {{ root : 'solutions-block2-title' }}> {intl.formatMessage({ id : `solutions-${category}-title-2`})} </Typography>
      <Typography variant = "body1" component = "p" classes = {{ root : "solutions-block2-content" }}>
        { intl.formatMessage({ id : `solutions-${category}-content-2-1`}) }
      </Typography>
      <List>
        {
          list1.map((l) => <ListItem>
            <ListItemIcon classes = {{ root : "solutions-list-item" }}></ListItemIcon>
            <Typography variant = "body1" component = "h6" classes = {{ root : 'solutions-list-item-text' }}> {intl.formatMessage({ id : `solutions-${category}-list-2-${l}`})} </Typography>
            {/* <ListItemText component = "h6" classes = {{ root : "solutions-list-item-text" }} primary = "Access control devices, such as access cards and card readers"></ListItemText> */}
          </ListItem>
          )
        }
      </List>
      {/* <Button classes = {{ root : 'solutions-button-more' }} variant = "contained" color = 'primary'> */}
        {/* <Link color = 'initial' classes = {{ root : 'solutions-link' }} href = {`/${intl.locale}/solutions/${category}/sfz-120`} underline = 'none'> */}
        {/* <Typography variant = "body1" component = "h6"> {intl.formatMessage({ id : `solutions-${category}-more-1`})} </Typography> */}

        {/* </Link> */}
      {/* </Button> */}
      </div>
    </div>
    {/* <Container className = "solutions-container">
    <Grid alignContent = "space-between" justify = "space-between" spacing = {3} container className = "solutions-card-list">
        {
          list1.map((l) => <Grid item><Card elevation = {5} className = {"solutions-card"}>
          <CardContent>
          <Typography variant="h5" component = "h3" className = {"solutions-card-title"} color = "textSecondary" gutterBottom>
              { intl.formatMessage({ id : `solutions-${category}-card-2-${l}-1`}) }
            </Typography>
            <Typography variant="body2" component="p">
              { intl.formatMessage({ id : `solutions-${category}-card-2-${l}-2`}) }
            </Typography>
            <Typography className = {""} color="textSecondary">
              { intl.formatMessage({ id : `solutions-${category}-card-2-${l}-3`}) }
            </Typography>
            <Typography variant="body2" component="p">
              { intl.formatMessage({ id : `solutions-${category}-card-2-${l}-4`}) }
            </Typography>
          </CardContent>
          <CardActions>
          </CardActions>
        </Card>
        </Grid>
          )
        }
      </Grid>
      </Container> */}
    <div class = 'solutions-block'>
      <img src = {Parking5} class = "solutions-block-bg" />
      <div class = 'solutions-block2'>
      <Typography variant = "h4" component = "h2" classes = {{ root : 'solutions-block2-title' }}> {intl.formatMessage({ id : `solutions-${category}-title-3`})} </Typography>
      <Typography variant = "body1" component = "p" classes = {{ root : "solutions-block2-content" }}>
        { intl.formatMessage({ id : `solutions-${category}-content-3-1`}) }
      </Typography>
      <List>
        {
          list1.map((l) => <ListItem>
            <ListItemIcon classes = {{ root : "solutions-list-item" }}></ListItemIcon>
            <Typography variant = "body1" component = "h6" classes = {{ root : 'solutions-list-item-text' }}> {intl.formatMessage({ id : `solutions-${category}-list-3-${l}`})} </Typography>
            {/* <ListItemText component = "h6" classes = {{ root : "solutions-list-item-text" }} primary = "Access control devices, such as access cards and card readers"></ListItemText> */}
          </ListItem>
          )
        }
      </List>
      {/* <Button classes = {{ root : 'solutions-button-more' }} variant = "contained" color = 'primary'>
        <Link color = 'initial' classes = {{ root : 'solutions-link' }} href = {`/${intl.locale}/solutions/${category}/cp-120`} underline = 'none'>
        <Typography variant = "body1" component = "h6"> {intl.formatMessage({ id : `solutions-${category}-more-2`})} </Typography>

        </Link>
      </Button> */}
      </div>
    </div>
 
   <Advice/>
    <Footer/>
    </div>
    
    </React.Fragment>


}



export default SolutionsParking